body {
  color: #404040;
  font-family: Calibri, "Trebuchet MS", sans-serif;
  // background-color: #F2F2F2;
  // height: 100vh;
  // overflow: hidden;
}

h6 {
  font-weight: 400 !important;
}

.clickable {
  cursor: pointer;
}

.selected {
  background-color: rgb(52, 152, 219) !important;
}

.wide-drawer {
  width: 700px;
}

.more-vertical-padding {
  padding-top: 12px;
  padding-bottom: 12px;
}

.new-voucher-request {
  display: table-cell;
  height: 112px;
  width: 372px;
  border: 1px solid gray;
  text-align: center;
  vertical-align: middle;
  border-radius: 8px;
  font-size: 18px;
  font-weight: bold;
  background: #e7f4f9;
  cursor: pointer;
}

.new-voucher-request .inner {
  display: inline-block;
}

/* Voucher Filter */

.voucher-status-sel {
  color: "lightgray";
  text-transform: capitalize;
  color: #242222;
  font-size: 14px;
  padding-right: 12px;
}

.voucher-status-sel .selected {
  background-color: rgb(207, 214, 230);
}

.voucher-status-sel .count {
  padding-left: 8px;
}

/* Voucher summary card */

.voucher-card {
  // height: 102px;
  width: 384px;
  display: table-cell;
  vertical-align: middle;
  // padding-left: 18px;
  padding: 10px;
}

/* Voucher status */

.voucher-status {
  font-size: 14px;
  text-transform: uppercase;
  font-style: normal;
  font-weight: bold;
}

.voucher-status.pending {
  color: #919191;
}
.voucher-status.rejected {
  color: #f2c3a7;
}
.voucher-status.issued {
  color: #85d3f2;
}
.voucher-status.paid {
  color: #404040;
}
.voucher-status.approved {
  color: #a7d9b3;
}

/* Voucher View */

// .voucher-view {
//   padding: 36px;
// }

// .voucher-view > {
//   margin-top: 12px;
//   margin-bottom: 12px;
// }

.result-filters {
  padding: 0;
  margin: 0;
  list-style: none;
  li {
    display: inline-block;
  }
}

ol li {
  margin: 20px 0;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

// .nav {
//   position: fixed;
//   left: 0;
//   width: 100%;
//   background-color: '#141414';
//   color: white;
//   text-align: center;
//   z-index: 8;
//   height: 70px;
//   -webkit-animation-name: example; /* Safari 4.0 - 8.0 */
//   -webkit-animation-duration: .6s; /* Safari 4.0 - 8.0 */
//   animation-name: example;
//   animation-duration: .6s;
//   animation-iteration-count: 2;
//   animation-direction: alternate;
//   animation-timing-function: ease;
//   transition: 0.6s;
// }

.filter-bar {
  top: 70;
  background-color: red;
}

.page-container {
  // border: 1px solid red;
  overflow: auto;
  // height: 100vh;
  // width: 100vw;
  margin: 0 auto;
}

.voucherFilter {
  padding: 18px 18px;
  //  position: absolute;
  // width: 100vw;
}

.confirmation-dialog {
  padding: 40px;
  .address {
    margin: 40px 0;
  }
}

